import { produce } from 'immer';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { REQUEST_STATUS, REQUEST_TYPE } from './constants';
import { FetchingStateMachine } from './machine';

const FETCHING_STATES = {};

const isAsyncAction = (val) =>
  Object.keys(val).every((item) =>
    ['baseType', 'request', 'success', 'failure', 'fulfill'].includes(item)
  );

export const createAction = (type, baseType) => {
  const actionCreator = (payload) => ({ type, payload });

  actionCreator.baseType = baseType;
  actionCreator.toString = () => type;
  actionCreator.type = type;

  return actionCreator;
};

export const createAsyncAction = (baseType) => {
  const requestType = `${baseType}_${REQUEST_TYPE.Request}`;
  const successType = `${baseType}_${REQUEST_TYPE.Success}`;
  const failureType = `${baseType}_${REQUEST_TYPE.Failure}`;
  const fulfillType = `${baseType}_${REQUEST_TYPE.Fulfill}`;

  const request = createAction(requestType, baseType);
  const success = createAction(successType, baseType);
  const failure = createAction(failureType, baseType);
  const fulfill = createAction(fulfillType, baseType);

  const asyncAction = {
    baseType,
    request,
    success,
    failure,
    fulfill,
  };

  const machine = new FetchingStateMachine(asyncAction);

  FETCHING_STATES[baseType] = machine;

  return asyncAction;
};

export const createFetchingSelector = (actions, { idle = true } = {}) => {
  const isArray = Array.isArray(actions);
  const isLoading = (item, state) => {
    const isAction = isAsyncAction(item);

    const action = isAction ? item : item.action;
    const status = state[action.baseType];

    switch (status) {
      case REQUEST_STATUS.Fulfilled:
        return false;
      case REQUEST_STATUS.Succeeded:
      case REQUEST_STATUS.Failed:
      case REQUEST_STATUS.Fetching:
        return true;
      case REQUEST_STATUS.Idle:
      default:
        return isAction ? idle : item.idle;
    }
  };

  return createSelector(
    (state) => state.eightFetching,
    (state) =>
      isArray
        ? actions.some((act) => isLoading(act, state))
        : isLoading(actions, state)
  );
};

// Think about this a bit more.  This seems to be becoming a common use case where you'd want to createFetchingSelector based on some condition
// inside the component body.
export const useFetchingSelector = (actions) => {
  const selector = useMemo(() => createFetchingSelector(actions), [actions]);
  const fetching = useSelector(selector);

  return fetching;
};

export const createReducer = (
  actionPrefix,
  initialState,
  reducerMap,
  options = {}
) => (state = initialState, action) => {
  return produce(state, (draftState) => {
    if (actionPrefix && action.type.startsWith(actionPrefix)) {
      setAsyncProps({ state: draftState, action, options });
    }

    const reducerFn = reducerMap[action.type];

    if (reducerFn) {
      reducerFn({ state: draftState, action });
    }
  });
};

export const createFetchingReducer = (stateKey = 'eightFetching') => {
  const initialState = Object.entries(FETCHING_STATES).reduce(
    (state, [baseType, machine]) => {
      state[baseType] = machine.initial;

      return state;
    },
    {}
  );

  return {
    [stateKey]: (state = initialState, action) =>
      produce(state, (draftState) => {
        const baseType = action.type.replace(
          /_REQUEST|_SUCCESS|_FAILURE|_FULFILL/,
          ''
        );

        const machine = FETCHING_STATES[baseType];

        if (machine) {
          machine.send(action.type);

          draftState[baseType] = machine.value;
        }
      }),
  };
};

export const fetchingReducer = {
  eightFetching: (state = {}, action) =>
    produce(state, (draftState) => {
      const baseType = action.type.replace(
        /_REQUEST|_SUCCESS|_FAILURE|_FULFILL/,
        ''
      );

      if (action.type.endsWith(REQUEST_TYPE.Request)) {
        draftState[baseType] = REQUEST_STATUS.Fetching;
      }
      if (action.type.endsWith(REQUEST_TYPE.Success)) {
        draftState[baseType] = REQUEST_STATUS.Succeeded;
      }
      if (action.type.endsWith(REQUEST_TYPE.Failure)) {
        draftState[baseType] = REQUEST_STATUS.Failed;
      }
      if (action.type.endsWith(REQUEST_TYPE.Fulfill)) {
        draftState[baseType] = REQUEST_STATUS.Fulfilled;
      }
    }),
};

// TODO: Get rid of this section

export const initialAsyncState = { fetching: true, error: null };

export const setAsyncProps = ({ state, action, options }) => {
  const setFetching = !(
    options.fetchingOverrides && options.fetchingOverrides[action.type]
  );

  if (action.type.endsWith(REQUEST_TYPE.Request)) {
    if (setFetching) {
      state.fetching = true;
    }
    state.error = null;
  }
  if (action.type.endsWith(REQUEST_TYPE.Success)) {
    if (setFetching) {
      state.fetching = false;
    }
    state.error = null;
  }
  if (action.type.endsWith(REQUEST_TYPE.Failure)) {
    if (setFetching) {
      state.fetching = false;
    }
    state.error = action.payload;
  }
};

// End "Get rid of this section"

export const tryCatchWrapper = (asyncAction, saga, handleError = () => {}) =>
  function* (...args) {
    try {
      yield call(saga, ...args);
    } catch (err) {
      yield call(handleError, err);
      yield put(asyncAction.failure(err));
    } finally {
      yield put(asyncAction.fulfill());
    }
  };

const ERROR_MESSAGE = (action, context) => `
[eight.js.store-common] 

You should only pass actions created with \`createAsyncAction\` to \`${context}\`
${action} will not be handled
`;

export const wrapLatest = (action, saga, handleError) => {
  if (!isAsyncAction(action)) {
    return console.error(ERROR_MESSAGE(action, 'wrapLatest'));
  }

  return takeLatest(
    action.request.type,
    tryCatchWrapper(action, saga, handleError)
  );
};

export const wrapEvery = (action, saga, handleError) => {
  if (!isAsyncAction(action)) {
    return console.error(ERROR_MESSAGE(action, 'wrapEvery'));
  }

  return takeEvery(
    action.request.type,
    tryCatchWrapper(action, saga, handleError)
  );
};
