import axios from 'axios';
import APILockService from './apiLock-service';

const instance = axios.create();
instance.interceptors.request.use(async function (config) {
  if (APILockService.isLocked(config)) {
    const { newAccessToken, tokenType } =
      await APILockService.waitTillUnlocked();
    if (config.tokenType === tokenType)
      config.headers = newAccessToken
        ? { ...config.headers, Authorization: `Bearer ${newAccessToken}` }
        : config.headers;
  }
  return config;
});

export default instance;
