export const REQUEST_STATUS = {
  Idle: 'IDLE',
  Fetching: 'FETCHING',
  Succeeded: 'SUCCEEDED',
  Failed: 'FAILED',
  Fulfilled: 'FULFILLED',
};

export const REQUEST_TYPE = {
  Request: 'REQUEST',
  Success: 'SUCCESS',
  Failure: 'FAILURE',
  Fulfill: 'FULFILL',
};
