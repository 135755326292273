import { REQUEST_STATUS } from './constants';

const getStatesForAction = (action) => ({
  [REQUEST_STATUS.Idle]: {
    on: {
      [action.request.type]: REQUEST_STATUS.Fetching,
    },
  },

  [REQUEST_STATUS.Fetching]: {
    on: {
      [action.success.type]: REQUEST_STATUS.Succeeded,
      [action.failure.type]: REQUEST_STATUS.Failed,
    },
  },

  [REQUEST_STATUS.Succeeded]: {
    on: {
      [action.fulfill.type]: REQUEST_STATUS.Fulfilled,
    },
  },

  [REQUEST_STATUS.Failed]: {
    on: {
      [action.fulfill.type]: REQUEST_STATUS.Fulfilled,
    },
  },

  [REQUEST_STATUS.Fulfilled]: {
    on: {
      [action.request.type]: REQUEST_STATUS.Fetching,
    },
  },
});

export class FetchingStateMachine {
  #states = {};
  #initial = REQUEST_STATUS.Idle;
  #value = REQUEST_STATUS.Idle;

  get currentState() {
    return this.#states[this.#value];
  }

  get initial() {
    return this.#initial;
  }

  get states() {
    return this.#states;
  }

  get value() {
    return this.#value;
  }

  constructor(asyncAction) {
    this.#states = getStatesForAction(asyncAction);
  }

  send(action) {
    const nextValue = this.currentState.on[action];

    // Fail silently if there is no valid transition from current state via this action
    if (!nextValue) return;

    this.#value = nextValue;
  }
}
