/* eslint camelcase: "off" */
import APILockService from './apiLock-service';
import instance from './instance';

const REFRESH_TRIGGERING_IN_SECONDS = 180;

export class FetchService {
  constructor(tokenService, authService) {
    this.tokenService = tokenService;
    this.apiLockService = APILockService;
    this.authService = authService;
  }

  fetch = async ({
    newVersionApi,
    body,
    bodyType,
    headers = {},
    method = 'POST',
    params,
    url,
    useMachineToken = false,
    useCustomToken = false,
  }) => {
    let request = {
      url,
      method,
      headers,
      params,
    };

    const { accessToken, machineToken, customToken } =
      this.tokenService.getTokens();

    let tokenType = '';
    let authorizationToken = '';

    if (useCustomToken && customToken) {
      tokenType = 'customToken';
      authorizationToken = customToken;
    } else if (useMachineToken) {
      tokenType = 'machineToken';
      authorizationToken = machineToken;
    } else {
      tokenType = 'accessToken';
      authorizationToken = accessToken;
    }

    authorizationToken = await this.validateToken(
      authorizationToken,
      tokenType,
      request
    );

    request = this.formatRequestHeaders(
      request,
      bodyType,
      body,
      newVersionApi,
      authorizationToken,
      tokenType
    );

    try {
      const response = await instance.request(request);
      return response;
    } catch (error) {
      throw error;
    }
  };

  refreshSelectedToken = async (type, lockToken, token) => {
    switch (type) {
      case 'accessToken':
        try {
          const refreshTokenResponse = await this.authService.refresh(
            lockToken
          );
          this.tokenService.setTokens({
            accessToken: refreshTokenResponse.accessToken,
            refreshToken: refreshTokenResponse.refreshToken,
          });
          return refreshTokenResponse.accessToken;
        } catch (error) {
          this.tokenService.setTokens({
            accessToken: null,
            refreshToken: null,
          });
          this.authService.logout();
          throw error;
        }
      case 'machineToken':
        try {
          const machineTokenResponse = await this.authService.fetchMachineToken(
            lockToken
          );
          this.tokenService.setTokens({
            machineToken: machineTokenResponse.accessToken,
          });
          return machineTokenResponse.accessToken;
        } catch (error) {
          this.tokenService.setTokens({
            machineToken: null,
          });
          this.authService.logout();
          throw error;
        }
      default:
        return token;
    }
  };

  validateToken = async (token, tokenType, request) => {
    let isTokenExpired = true;
    let finalAuthorizationToken = token;

    if (token)
      isTokenExpired = this.tokenService.isExpiring(
        token,
        REFRESH_TRIGGERING_IN_SECONDS
      );

    if (isTokenExpired && !this.apiLockService.isLocked(request)) {
      const lockToken = new Date().toISOString();

      this.apiLockService.lock(lockToken);

      const newAccessToken = await this.refreshSelectedToken(
        tokenType,
        lockToken,
        token
      );

      finalAuthorizationToken = newAccessToken;

      this.apiLockService.releaseLock(
        lockToken,
        tokenType,
        finalAuthorizationToken
      );
    }

    return finalAuthorizationToken;
  };

  formatRequestHeaders = (
    request,
    bodyType,
    body,
    newVersionApi,
    authorizationToken,
    tokenType
  ) => {
    switch (bodyType) {
      case 'urlencoded':
        request.data = qs.stringify(body);
        request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        break;
      case 'json':
      default:
        request.data = body;
        if (newVersionApi) {
          request.headers['Content-Type'] = 'application/json';
          request.headers['x-api-version'] = newVersionApi;
        }
        break;
    }
    request.headers.Authorization = `Bearer ${authorizationToken}`;
    request.tokenType = tokenType;
    return request;
  };
}
