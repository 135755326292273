import { initialAsyncState, createReducer } from 'eight.js.store-common';

import {
  fetchMachineTokenAsync,
  loginAsync,
  logoutAsync,
  refreshAsync,
  setAccessToken,
  setMachineToken,
  setRefreshToken,
  tokenLogin,
} from './actions';

const initialState = {
  ...initialAsyncState,
  fetching: false,
  refreshing: false,
  loginLoading: false,
  accessToken: null,
  refreshToken: null,
  machineToken: null,
};

export const authReducer = createReducer('@@eight.js.auth', initialState, {
  [fetchMachineTokenAsync.success]: ({ state, action }) => {
    state.machineToken = action.payload.accessToken;
  },

  [loginAsync.request]: ({ state }) => {
    state.loginLoading = true;
    state.fetching = true;
  },

  [loginAsync.success]: ({ state, action }) => {
    state.accessToken = action.payload.accessToken;
    state.refreshToken = action.payload.refreshToken;
    state.loginLoading = false;
    state.fetching = false;
  },

  [loginAsync.failure]: ({ state }) => {
    state.loginLoading = false;
    state.fetching = false;
    state.accessToken = null;
  },

  [logoutAsync.success]: ({ state }) => {
    state.accessToken = null;
  },

  [refreshAsync.request]: ({ state }) => {
    state.refreshing = true;
    state.fetching = true;
  },

  [refreshAsync.success]: ({ state, action }) => {
    state.accessToken = action.payload.accessToken;
    state.refreshToken = action.payload.refreshToken;
    state.refreshing = false;
    state.fetching = false;
  },

  [refreshAsync.failure]: ({ state }) => {
    state.refreshing = false;
    state.fetching = false;
  },

  [tokenLogin]: ({ state, action }) => {
    state.accessToken = action.payload.accessToken;
  },

  [setAccessToken]: ({ state, action }) => {
    state.accessToken = action.payload;
  },
  [setMachineToken]: ({ state, action }) => {
    state.machineToken = action.payload;
  },
  [setRefreshToken]: ({ state, action }) => {
    state.refreshToken = action.payload;
  },
});
