export class DIContainer {
  constructor() {
    this.services = new Map();
    this.singletons = new Map();
  }

  register(name, klass, dependencies) {
    this.services.set(name, {
      Definition: klass,
      dependencies: dependencies,
    });
  }

  singleton(name, klass, dependencies) {
    this.services.set(name, {
      Definition: klass,
      dependencies: dependencies,
      singleton: true,
    });
  }

  get(name) {
    const klass = this.services.get(name);

    if (klass.singleton) {
      const singletonInstance = this.singletons.get(name);

      if (singletonInstance) {
        return singletonInstance;
      } else {
        const newSingletonInstance = this.createInstance(klass);

        this.singletons.set(name, newSingletonInstance);

        return newSingletonInstance;
      }
    }

    return this.createInstance(klass);
  }

  getResolvedDependencies(service) {
    let classDependencies = [];

    if (service.dependencies) {
      classDependencies = service.dependencies.map((dep) => {
        return this.get(dep);
      });
    }

    return classDependencies;
  }

  createInstance(service) {
    return new service.Definition(...this.getResolvedDependencies(service));
  }
}
