import {
  REQUEST_TYPE,
  tryCatchWrapper,
  wrapLatest,
} from 'eight.js.store-common';
import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import {
  fetchMachineTokenAsync,
  loginAsync,
  logoutAsync,
  refreshAsync,
} from './actions';

export const createAuthActionWatcher = ({ tokenService, authService }) => {
  function* fetchMachineToken() {
    const { machineToken } = tokenService.getTokens();

    if (machineToken && !tokenService.isExpired(machineToken)) return;

    const response = yield call(authService.fetchMachineToken, {});

    tokenService.setTokens({ machineToken: response.accessToken });

    yield put(fetchMachineTokenAsync.success(response));
  }

  function* login({ payload }) {
    yield call(fetchMachineToken);

    const response = yield call(authService.login, {
      username: payload.username,
      password: payload.password,
    });

    tokenService.setTokens({
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
    });

    yield put(loginAsync.success(response));
  }

  function* logout() {
    yield call(fetchMachineToken);

    yield call(authService.logout);
    
    yield put(logoutAsync.success());
    
    tokenService.clearTokens();

  }

  function* refresh() {
    const response = yield call(authService.refresh);

    tokenService.setTokens({
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
    });

    yield put(refreshAsync.success(response));
  }

  return function* authActionWatcher() {
    yield wrapLatest(fetchMachineTokenAsync, fetchMachineToken);
    yield wrapLatest(loginAsync, login);
    yield wrapLatest(logoutAsync, logout);
    yield takeLeading(
      refreshAsync.request.type,
      tryCatchWrapper(refreshAsync, refresh)
    );
  };
};
