import { createSelector } from 'reselect';

export const createSelectors = () => {
  const authStateSelector = (state) => state.eightAuth;

  const accessTokenSelector = createSelector(
    authStateSelector,
    (state) => state.accessToken
  );

  const refreshTokenSelector = createSelector(
    authStateSelector,
    (state) => state.refreshToken
  );
  const authFetchingSelector = createSelector(
    authStateSelector,
    (state) => state.fetching
  );

  const authErrorSelector = createSelector(
    authStateSelector,
    (state) => state.error
  );

  const machineTokenSelector = createSelector(
    authStateSelector,
    (state) => state.machineToken
  );

  const refreshingSelector = createSelector(
    authStateSelector,
    (state) => state.refreshing
  );

  const loginLoadingSelector = createSelector(
    authStateSelector,
    (state) => state.loginLoading
  );

  return {
    accessTokenSelector,
    refreshTokenSelector,
    authFetchingSelector,
    authErrorSelector,
    machineTokenSelector,
    refreshingSelector,
    loginLoadingSelector,
  };
};
