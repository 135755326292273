import jwtDecode from 'jwt-decode';

export class TokenService {
  refreshTokenKey = 'refreshToken';
  accessTokenKey = 'token';
  tokenExpirationKey = 'tokenExpiration';

  getTokens = () => {
    const refreshToken =
      this.refreshToken || window.localStorage.getItem(this.refreshTokenKey);

    const tokenExpiration =
      this.tokenExpiration || window.localStorage.getItem(this.tokenExpiration);

    return {
      accessToken: this.accessToken,
      machineToken: this.machineToken,
      customToken: this.customToken,
      refreshToken,
      tokenExpiration,
    };
  };

  clearTokens = () => {
    this.accessToken = null;
    this.refreshToken = null;
    this.tokenExpiration = null;
    this.customToken = null;

    window.localStorage.removeItem(this.refreshTokenKey);
    window.localStorage.removeItem(this.accessTokenKey);
    window.localStorage.removeItem(this.tokenExpiration);
  };

  setTokens = ({
    accessToken,
    machineToken,
    customToken,
    refreshToken,
    tokenExpiration,
  }) => {
    if (accessToken) {
      this.accessToken = accessToken;
    }
    if (machineToken) {
      this.machineToken = machineToken;
    }
    if (refreshToken) {
      this.refreshToken = refreshToken;
      window.localStorage.setItem(this.refreshTokenKey, refreshToken);
    }
    if (customToken) {
      this.customToken = customToken;
    }
    if (tokenExpiration) {
      this.tokenExpiration = tokenExpiration;

      window.localStorage.setItem(this.tokenExpirationKey, tokenExpiration);
    }
  };

  decodeToken = (token) => jwtDecode(token);

  isExpired = (token) => {
    const { exp } = this.decodeToken(token);
    return new Date().getTime() / 1000 >= exp;
  };

  isExpiring = (token, limit) => {
    const { exp } = this.decodeToken(token);
    return new Date().getTime() / 1000 + limit >= exp;
  };
}
