/* eslint camelcase: "off" */
import axios from 'axios';

export class AuthService {
  constructor(config, tokenService) {
    this.config = config;
    this.tokenService = tokenService;

    this.identityAPI = axios.create({
      method: 'POST',
      baseURL: config.identityAPI,
    });

    this.apiToAuthenticate = axios.create({
      method: 'POST',
      baseURL: config.identityAPI,
    });
  }

  fetchMachineToken = async (lockToken) => {
    const machineTokenResponse = await this.identityAPI.request({
      url: '/machine/token',
      data: {
        grantType: 'client_credentials',
        clientId: this.config.machineId,
        clientSecret: this.config.machineSecret,
        scopes: this.config.fetchMachineScope
      },
      lockToken: lockToken ?? null,
    });

    return machineTokenResponse.data;
  };

  login = async ({ username, password }) => {
    const { machineToken: machineAccessToken } = this.tokenService.getTokens();

    const loginResponse = await this.apiToAuthenticate.request({
      url: `/web/${this.config.type}/login`,
      headers: {
        Authorization: `Bearer ${machineAccessToken}`,
      },
      data: {
        grantType: 'password',
        clientId: this.config.clientId,
        clientSecret: this.config.clientSecret,
        username,
        password,
        scopes: this.config.loginScope
      },
    });

    return loginResponse.data;
  };

  logout = async () => {
    const { refreshToken, accessToken } = this.tokenService.getTokens();

    return await this.apiToAuthenticate.request({
      url: '/web/logout',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        clientId: this.config.clientId,
        clientSecret: this.config.clientSecret,
        refreshToken,
      },
    });
  };

  refresh = async (lockToken) => {
    const { refreshToken } = this.tokenService.getTokens();

    const refreshTokenResponse = await this.apiToAuthenticate.request({
      url: '/web/refresh',
      data: {
        clientId: this.config.clientId,
        clientSecret: this.config.clientSecret,
        refreshToken,
      },
      lockToken: lockToken ?? null,
    });

    return refreshTokenResponse.data;
  };
}
