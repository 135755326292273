import {
  fetchMachineTokenAsync,
  loginAsync,
  logoutAsync,
  refreshAsync,
  tokenLogin,
  setAccessToken,
  setMachineToken,
  setRefreshToken,
} from './actions';
import { AuthService } from './auth-service';
import { FetchService } from './fetch-service';
import instance from './instance';
import { authReducer as authReducerFn } from './reducer';
import { createAuthActionWatcher } from './sagas';
import { createSelectors } from './selectors';
import { TokenService } from './token-service';

export const createAuthLib = (config) => {
  const authReducer = { eightAuth: authReducerFn };
  const selectors = createSelectors();
  const tokenService = new TokenService();
  const authService = new AuthService(config, tokenService);
  const fetchService = new FetchService(tokenService, authService);
  const authActionWatcher = createAuthActionWatcher({
    tokenService,
    authService,
  });
  const axiosInstance = instance;

  return {
    authActionWatcher,
    authReducer,
    fetchMachineTokenAsync,
    loginAsync,
    tokenLogin,
    logoutAsync,
    refreshAsync,
    setAccessToken,
    setMachineToken,
    setRefreshToken,
    tokenService,
    fetchService,
    authService,
    axiosInstance,
    ...selectors,
  };
};
