import { createAction, createAsyncAction } from 'eight.js.store-common';

export const fetchMachineTokenAsync = createAsyncAction(
  '@@eight.js.auth/FETCH_MACHINE_TOKEN'
);
export const loginAsync = createAsyncAction('@@eight.js.auth/LOGIN');
export const logoutAsync = createAsyncAction('@@eight.js.auth/LOGOUT');
export const refreshAsync = createAsyncAction('@@eight.js.auth/REFRESH');
export const tokenLogin = createAction('@@eight.js.auth/TOKEN_LOGIN');
export const setAccessToken = createAction('@@eight.js.auth/SET_ACCESS_TOKEN');
export const setMachineToken = createAction(
  '@@eight.js.auth/SET_MACHINE_TOKEN'
);
export const setRefreshToken = createAction(
  '@@eight.js.auth/SET_REFRESH_TOKEN'
);
